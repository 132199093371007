import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      exibirFiltro: true,
      breadcrumbs: [],
    };
  },
  computed: {
    ...mapGetters('filtros', [
      'filtroLiquidacaoContratoNivel3',
    ]),
  },
  watch: {
    filtroLiquidacaoContratoNivel3(val) {
      if (val && this.breadcrumbs[2]) {
        this.breadcrumbs[2].text = this.montarTextoNivel3(val);
      }
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroLiquidacaoContratoNivel2',
      'setFiltroLiquidacaoContratoNivel3',
    ]),
    iniciaBreadcrumbs() {
      if (this.breadcrumbs.length > 0) return;
      this.breadcrumbs = [{
        text: this.$tc('label.contrato', 1).concat(' - ').concat(this.$tc('label.pagamento', 1)),
        to: 'pagamento-contrato',
        disabled: true,
        nivel: 1,
      }];
    },
    navegarBreadcrumbs(item) {
      if (item.disabled) {
        return;
      }
      const visaoContrato = this.$router.currentRoute.name === 'listarPagamentosContratoNivel2Contrato'
        || this.$router.currentRoute.name === 'detalharPagamentoContratoContrato';

      let visao = 'Status';
      if (visaoContrato) {
        visao = 'Contrato';
      }

      const params = {
        visao,
      };

      let query;

      if (item.status) {
        params.status = item.status;
      }

      if (item.idContrato) {
        params.idContrato = item.idContrato;
        query = { origem: item.text };
      }

      this.$router.push({ name: item.to, params, query });
    },
    alteracaoRota(to) {
      this.iniciaBreadcrumbs();
      this.exibirFiltro = true;
      if (to.name === 'listarPagamentosContratoNivel2Status') {
        this.adicionarItemBreadcrumbsStatus(to);
      } else if (to.name === 'listarPagamentosContratoNivel2Contrato') {
        this.adicionarItemBreadcrumbsContrato(to);
      } else if (to.name === 'pagamento-contrato') {
        this.removerItemBreadcrumbs();
      } else {
        this.exibirFiltro = false;
        this.abrirFiltro = false;
        if (to.name === 'detalharPagamentoContratoStatus') {
          this.adicionarItemBreadcrumbsDetalhesStatus(to);
        } else if (to.name === 'detalharPagamentoContratoContrato') {
          this.adicionarItemBreadcrumbsDetalhesContrato(to);
        }
      }
    },
    adicionarItemBreadcrumbsStatus(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: this.$tc(`status_entidade_pagamento.${to.params.status.toLowerCase()}`, 1),
          status: to.params.status,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroLiquidacaoContratoNivel3(null);
    },
    adicionarItemBreadcrumbsContrato(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idContrato: to.params.idContrato,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroLiquidacaoContratoNivel3(null);
    },
    adicionarItemBreadcrumbsDetalhesStatus(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: this.$tc(`status_entidade.${to.params.status.toLowerCase()}`, 1),
          status: to.params.status,
          to: 'listarPagamentosContratoNivel2Status',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesContrato(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem ? to.query.origem : this.breadcrumbs[1].text,
          idContrato: to.params.idContrato,
          to: 'listarPagamentosContratoNivel2Contrato',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    montarTextoNivel3(params) {
      let texto = '';
      if (params.idContrato) {
        texto = texto.concat(params.idContrato).concat(' - ');
      } else if (params.codContrato) {
        texto = texto.concat(params.codContrato).concat(' - ');
      } else if (params.cod_contrato) {
        texto = texto.concat(params.cod_contrato).concat(' - ');
      }
      if (params.idAcao) {
        texto = texto.concat(params.idAcao).concat(' - ');
      } else if (params.codAcao) {
        texto = texto.concat(params.codAcao).concat(' - ');
      } else if (params.cod_acao) {
        texto = texto.concat(params.cod_acao).concat(' - ');
      }
      if (params.codApuracao) {
        texto = texto.concat(params.codApuracao).concat(' - ');
      } else if (params.cod_apuracao) {
        texto = texto.concat(params.cod_apuracao).concat(' - ');
      }
      if (params.id) {
        texto = texto.concat(params.id);
      }
      return texto;
    },
    removerItemBreadcrumbs() {
      this.breadcrumbs[0].disabled = true;
      this.breadcrumbs = [this.breadcrumbs[0]];
      this.setFiltroLiquidacaoContratoNivel2(null);
      this.setFiltroLiquidacaoContratoNivel3(null);
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.alteracaoRota(to);
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.alteracaoRota(to);
    });
  },
};
